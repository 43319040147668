<template>
  <header class="flex justify-end items-center gap-x-6 px-5 py-0">
    <SearchBar :base-url="baseUrl" />
    <!-- Spacer -->
    <div class="flex-1"></div>
    <Help :help-menu="config.helpMenu" :teamBrainUrl="teamBrainUrl" />
    <Links :links="config.links" :base-url="baseUrl" />
    <!-- TODO : ICON NOUVEAUTE -->
    <Notification :base-url="baseUrl" />
    <Avatar :user="config.utilisateurConnecte" :base-url="baseUrl" />
  </header>
</template>

<script setup lang="ts">
import "@shoelace-style/shoelace/dist/components/dropdown/dropdown.js";
import "@shoelace-style/shoelace/dist/components/menu/menu.js";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item.js";
import "@shoelace-style/shoelace/dist/components/input/input.js";
import Avatar from "./Avatar.vue";
import Help from "./Help.vue";
import Links from "./Links.vue";
import SearchBar from "./SearchBar.vue";
import Notification from "./Notification.vue";
import { EnvironmentVariables } from "@/type/api";
import { computed, ref } from "vue";

const defaultData = {
  utilisateurConnecte: undefined,
  helpMenu: [],
  links: [],
  "tb-url": "",
} as EnvironmentVariables;

const config = ref(defaultData);
const baseUrl = ref("");
const teamBrainUrl = computed(() => config.value["tb-url"]);

const stripTrailingSlash = (s: string): string =>
  s.endsWith("/") ? s.slice(0, -1) : s;

const checkEnvironmentVariables = (newData: object) => {
  const result = EnvironmentVariables.safeParse(newData);
  if (!result.success) {
    console.error(result.error);
    return defaultData;
  }
  return result.data;
};

const setStandaloneConfig = (value: EnvironmentVariables, urlBase: string) => {
  config.value = checkEnvironmentVariables(value);
  baseUrl.value = stripTrailingSlash(urlBase);
};

defineExpose({ setStandaloneConfig });
</script>

<style lang="scss">
@use "@developpeur/core-lib-front/scss/shoelace/customization/avatar.scss";
@use "@developpeur/core-lib-front/scss/shoelace/customization/dropdown.scss";
@use "@developpeur/core-lib-front/scss/shoelace/customization/input.scss" as
  custom_input;
@use "@developpeur/core-lib-front/scss/shoelace/radius.scss";
@use "@developpeur/core-lib-front/scss/shoelace/spacing.scss";
@use "@developpeur/core-lib-front/scss/shoelace/shadow.scss";
@use "@developpeur/core-lib-front/scss/shoelace/panel.scss";
@use "@developpeur/core-lib-front/scss/shoelace/input.scss";
@use "@developpeur/core-lib-front/core-lib-front.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

sl-input::part(input)::placeholder {
  font-style: italic;
  color: var(--its-color-neutral-800);
}

sl-input::part(base) {
  padding-right: 0.5em;
}
</style>
